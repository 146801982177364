import React, {useContext, Suspense} from "react";
import axios from "axios";
import {RouterConfig} from "routes/router-config";
import {CssBaseline, Grid} from "@material-ui/core";
import dotenv from 'dotenv';

import {AuthContext} from "services/providers/auth-provider";

import {Loading} from "components/loading";
import {Maintenance} from "components/maintenance";
import './App.css';
import Cookies from "js-cookie";


function App() {
  window.API_URL = 'https://api-externe.ordago.fr/api'	

  window.API_URLi = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : window.location.origin.toString();
  const {isAuthenticated} = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false)
  const [routes, setRoutes] = React.useState([])
  const [maintenance, setMaintenance] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    axios.get(window.API_URL + "/api/v1.0/routes/").then(response => {
      let maintenanceType = Cookies.get("no-maintenance") ? false : response.data.maintenance
      setLoading(false)
      setRoutes(response.data.routes)
      setMaintenance(maintenanceType)
    })
  }, [isAuthenticated])

  return (
    <>
      {maintenance ? (
        <Maintenance/>
      ) : (
        <Grid container className="App">
          <Suspense fallback={<Loading/>}>
            <CssBaseline/>
            <Grid item xs={12}>
              {loading ?
                <Loading/>
                :
                <RouterConfig routes={routes}/>
              }
            </Grid>
          </Suspense>
        </Grid>
      )}
    </>
  );
}

export default App;

import axios from 'axiosConfig';

export const authLogin = async (credentials) => {
  // 1. Effectuer la requête GET pour obtenir le jeton CSRF
  try {
    const csrfResponse = await axios.get(window.API_URL + "/api/v1.0/set-csrf/");
    const csrfToken = csrfResponse.data.csrftoken;

    // 2. Utiliser le jeton CSRF dans la requête POST pour la connexion
    const loginResponse = await axios.post(window.API_URL + "/api/v1.0/login/", {
      username: credentials.username,
      password: credentials.password,
      userid: credentials.userid
    }, {
      headers: {
        'X-CSRFToken': csrfToken, // Inclure le jeton CSRF dans les en-têtes
      },
      withCredentials: true, // Assurez-vous que les cookies sont inclus dans la requête
    });

    return loginResponse.data; // Retourner les données de la réponse, si nécessaire
  } catch (error) {
    console.error('Erreur lors de la connexion:', error);
    throw error; // Vous pouvez gérer l'erreur ici ou la propager à l'appelant
  }
};

export const authLogout = () => {
  return fetch(window.API_URL + "/api/v1.0/logout/", {method: "GET"})
}

export const checkIsAuthenticated = () => {
  return axios.get(window.API_URL + "/api/v1.0/check-auth/")
}

export const testModeApi = () => {
  return axios.get(window.API_URL + "/api/mode_api/")
}

export const sendTestModeApi = (checked) => {
  let form_data = new FormData();
  form_data.append('sewan_test_url', checked);
  return axios.post(window.API_URL + "/api/mode_api/", form_data, {})
}
